import { Flex } from "front-commons/ds";
import styled from 'styled-components';

export const FlexTooltip = styled(Flex)`
	> button {
		pointer-events: auto;
	}

	> div div {
		max-width: 250px;
	}
`;