import type { ImageProps } from "front-commons/ds/components/Image/interfaces";
import type { CreateQuizResponse } from "services/customer/interfaces";
import type { PosLinkValidateResponse } from "services/pos/interfaces";

export type ExceptionTypes = 'CNPJ' | 'BLOCKED' | 'LAST_TRY' | 'SERVICE_UNAVAILABLE';
export type CurrentStepTypes = 'CNPJ_INPUT' | 'CUSTOMER_VALIDATION' | 'REQUEST_SUCCESS';
type CNPJsRequestStatusTypes = "INVALID_CNPJ" | "POS_UNAVAILABLE" | "ALREADY_RELATED" | "NO_APPROVER" | "VALID" | "SERVICE_UNAVAILABLE" | "ACCESS_REQUESTED" | "ACCESS_GRANTED" | "ACCESS_GRANTED_MASTER" | "PENDING_VALIDATION" | "FIXED"

export interface ExceptionHandlerParams {
	get: ExceptionTypes | undefined;
	set(newException: ExceptionTypes): void;
	clear(): void;
}

export interface NavigationHandlerParams {
	next(POSLinkRequest?: string[]): void;
	abort(): void;
	reset(): void;
	tryAgain(): void;
}

export interface CNPJHandlerParams {
	raw: RequestCNPJsParams[];
	set: React.Dispatch<React.SetStateAction<RequestCNPJsParams[]>>
	get(replaceData?: RequestCNPJsParams[]): {
		valid: string[];
		withException: RequestCNPJsParams[];
	}
	edit(index: number, value: string): void;
	delete(index: number[]): void;
}

export interface StepSupportParams {
	title?: string;
	imageName?: ImageProps['name'];
	description?: string;
}

export interface RequestCNPJsParams {
	cnpj: string;
	index: number;
	status: CNPJsRequestStatusTypes;
}

export interface FlowWrapperProps {
	loading?: boolean;
	children: React.ReactNode;
	currentStep?: CurrentStepTypes;
	navigateHandler: NavigationHandlerParams;
	exceptionHandler: ExceptionHandlerParams;
	POSLinkRequestAmount?: number;
}

export interface StepSupportProps extends Pick<FlowWrapperProps, 'loading' | 'currentStep' | 'exceptionHandler'> { }

export interface SuccessProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'POSLinkRequestAmount'> { }

export interface ExceptionsProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'exceptionHandler'> { }

//! CNPJ STEP
export interface CNPJStepProps extends Pick<FlowWrapperProps, 'exceptionHandler' | 'navigateHandler'> {
	loadings: {
		createQuizLoading: boolean;
		posLinkStatusLoading: boolean;
		posLinkRequestLoading: boolean;
	}
	CNPJHandler: CNPJHandlerParams;
}

interface CNPJStepCommonsProps {
	loadings: {
		validatingLoading: boolean;
		createQuizLoading: boolean;
		posLinkStatusLoading: boolean;
		posLinkRequestLoading: boolean;
	}
	handleValidateCNPJs(params?: Record<string, unknown> | undefined): Promise<PosLinkValidateResponse | undefined>
}

export interface LinkRequestProps extends Pick<CNPJStepProps, 'navigateHandler'>, CNPJStepCommonsProps {
	setRequestCNPJs: CNPJHandlerParams['set'];
}

export interface LinkExceptionFixProps extends Pick<CNPJStepProps, 'navigateHandler'>, CNPJStepCommonsProps {
	CNPJHandler: CNPJHandlerParams;
	handleIgnoreExceptions(): void
}

export interface EditableContentProps extends Pick<RequestCNPJsParams, 'index' | 'status'> {
	value: string;
	editable: boolean;
	handleEditCNPJ: LinkExceptionFixProps['CNPJHandler']['edit'];
}

//! VALIDATION STEP
export interface ValidationStepProps extends Pick<FlowWrapperProps, 'navigateHandler' | 'exceptionHandler'> {
	quiz: CreateQuizResponse;
	loadings: {
		createQuizLoading: boolean;
		posLinkStatusLoading: boolean;
		posLinkRequestLoading: boolean;
	}
}