import { InputProps } from "front-commons/ds/components/Input/interfaces";
import { useFetch } from "front-commons/hooks";
import { UseFetchProps } from "front-commons/hooks/useFetch/interfaces";
import { useCallback, useMemo, useState } from "react";
import { findMyPharmaciesNoHandler, getUserSimulatedPosSuggestions } from "services/pos";
import useCustomer from "stores/customer";
import usePos from "stores/pos";

interface UsePosSearch<T> extends Pick<UseFetchProps<T, any>, 'onSuccess' | 'onError'> {
	refetchOnClear?: boolean;
	commonUseFetchParams?: Partial<UseFetchProps<T, any>>;
	onSearchClean?(): void;
}

export default function usePosSearch<T = any>({
	refetchOnClear,
	commonUseFetchParams,
	onError,
	onSuccess,
	onSearchClean,
}: UsePosSearch<T>) {
	const { handleIncrementToMyPos } = usePos();
	const { simulatedViewType } = useCustomer();
	const isSimulated = !!simulatedViewType();

	const [helpText, setHelpText] = useState<string | undefined>(undefined);
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);
	const [searchedWithFilter, setSearchedWithFilter] = useState(false);

	const handleInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(el.currentTarget.value);

		if (helpText) setHelpText(undefined);
	};

	const { fetch: handleSearchWithFilters, loading: commonLoading, Pagination } = useFetch<any>({
		...commonUseFetchParams,
		onSuccess(response) {
			handleIncrementToMyPos(response?.content || []);
			onSuccess?.(response);
		},
		onError,
		fetchFunction: findMyPharmaciesNoHandler,
	});

	const { fetch: handleSearchWithCNPJ, loading: simulatedLoading } = useFetch<any>({
		onSuccess(response) {
			handleIncrementToMyPos([
				...response.map((pos: any) => ({
					...pos,
					role: 'OWNER' as any,
					status: 'READY' as any,
				})),
			]);
			onSuccess?.(response);
		},
		onError,
		fetchFunction: getUserSimulatedPosSuggestions,
	});

	const searchType = useMemo(() => {
		if (inputValue === '') {
			onSearchClean?.();
			setInputValue(undefined);
			if (refetchOnClear && searchedWithFilter) isSimulated ? handleSearchWithCNPJ() : handleSearchWithFilters();
			if (searchedWithFilter) setSearchedWithFilter(false);
		}

		if (inputValue && inputValue.length > 1) {
			if (Number(CNPJ(inputValue).unmask)) return 'cnpj';
			return 'tradeName';
		}

		return 'tradeName';
	}, [inputValue]);

	const handleSearch = useCallback(() => {
		if (!inputValue) return;

		if (isSimulated || searchType === 'cnpj') {
			const cnpj = CNPJ(inputValue).unmask;

			if (cnpj.length !== 14) return setHelpText('Digite o CNPJ completo');

			setSearchedWithFilter(true);
			return (isSimulated ? handleSearchWithCNPJ : handleSearchWithFilters)({ cnpj });
		}

		setSearchedWithFilter(true);
		return handleSearchWithFilters({ tradeName: inputValue });
	}, [inputValue, searchType]);

	const inputProps: Omit<InputProps, 'name'> = {
		type: searchType === "cnpj" ? 'cnpj' : 'text',
		value: inputValue,
		loading: commonLoading || simulatedLoading,
		helpText,
		rightIcon: {
			name: 'search',
			onClick: handleSearch,
		},
		onChange: handleInputChange,
		onPressEnter: handleSearch,
	};

	return {
		search: inputValue,
		loading: inputProps.loading || false,
		inputProps,
		Pagination,
	}
}