import { Button, Flex, Icon, Skeleton, Typography } from 'front-commons/ds';
import { HeaderPromotionDetails } from './styles';
import useDrawer from 'stores/drawer';
import { getRemainingDaysText } from 'shared/promotionDrawer';
import { PromotionDrawerHeader } from './interfaces';

export default function PromotionDrawerHeader({
	loading,
	endDate,
	description,
	showReturnButton,
	onTransitionCloseDrawer,
}: PromotionDrawerHeader) {
	const { handleCloseAllDrawers } = useDrawer();

	const daysToEnd = getRemainingDaysText(endDate || '');

	return (
		<HeaderPromotionDetails
			alignItems={{ medium: 'center' }}
			justifyContent="space-between"
			padding="16px"
			height={{ small: '80px', medium: '54px' }}
			minHeight={{ small: '80px', medium: '54px' }}
			position="sticky"
			top="0"
			backgroundColor="--surface-white"
			zIndex={999}
		>
			{loading ? (
				<Skeleton width="100%" height="24px" />
			) : (
				<Flex gap="8px" direction={{ small: 'column', medium: 'row' }}>
					<Flex gap="8px" alignItems="center">
						{showReturnButton && (
							<Button variant="none" type="button" onClick={onTransitionCloseDrawer}>
								<Icon name="arrow_left_alt" color="--surface-accent-dark" size="20px" />
							</Button>
						)}

						<Typography variant="Paragraph/Semibold">{description}</Typography>
					</Flex>

					<Flex alignItems="center" gap="8px">
						<Flex width="5px" height="5px" borderRadius="50%" backgroundColor="--surface-accent" />
						{daysToEnd}
					</Flex>
				</Flex>
			)}

			<Button
				variant="none"
				type="button"
				onClick={() => {
					handleCloseAllDrawers();
				}}
			>
				<Icon name="close" color="--surface-accent-dark" size="20px" />
			</Button>
		</HeaderPromotionDetails>
	);
}
