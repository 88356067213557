import {
	Button,
	Flex,
	getThemeFont,
	getThemeFontSize,
	getThemeFontWeight,
	getThemeMedia,
	getThemeShadow,
	getThemeSpace,
	Typography,
} from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const PromotionProductContainer = styled(Flex) <{ limitReached: boolean }>`
	background: var(--surface-white);
	box-shadow: ${getThemeShadow('1')};
	border-radius: 8px;
	opacity: 1;
	position: relative;
	width: 100%;
	overflow: hidden;
	max-width: 300px;

	${({ limitReached }) =>
		limitReached &&
		css`
			> div:not(.bottom-section) {
				opacity: 0.5;
			}

			.bottom-section {
				.price-section {
					opacity: 0.5;
				}
			}
		`}
`;

export const PromotionCardProductsThumbnails = styled.div<{ thumbsLength?: number }>`
	display: flex;
	align-items: flex-end;
	gap: ${getThemeSpace('8')};
	position: relative;
	overflow: hidden;
	margin-top: ${getThemeSpace('8')};

	& > div:not(div:nth-child(1), div:nth-child(2), div:nth-child(3)) {
		display: none;
	}

	${({ thumbsLength }) =>
		thumbsLength &&
		thumbsLength > 2 &&
		css`
			&::before {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 50%;
				left: calc(((115px + 4px) * 2) - 24px);
				transform: translateY(-50%);

				z-index: 1;

				border-radius: 40px;
				background: var(--surface-accent-dark);

				width: 32px;
				height: 32px;

				color: var(--text-invert);
				font: ${getThemeFontWeight('bold')} ${getThemeFontSize(thumbsLength >= 100 ? '14' : '16')}
					${getThemeFont('sourceSansPro')};
				content: '+ ${thumbsLength - 2}';
				white-space: nowrap;
			}
		`}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			& > div {
				&:nth-child(3) {
					display: flex !important;
				}

				&:not(div:nth-child(1), div:nth-child(2), div:nth-child(3)) {
					display: none;
				}
			}

			${({ thumbsLength }) =>
		thumbsLength &&
		thumbsLength > 2 &&
		css`
					&::before {
						left: auto;
						right: 0;

						content: '+ ${thumbsLength - 2}';
					}
				`}
		}
	}
`;

export const PromotionCardProductsImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	border: 1px solid var(--border-primary);

	min-width: 96px;
	min-height: 96px;

	img {
		max-width: 75px;
		max-height: 75px;
	}
`;

export const PromotionProductCardPriceWrapper = styled.div`
	margin-top: ${getThemeSpace('8')};
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	& > span {
		display: flex;
		align-items: center;
		gap: ${getThemeSpace('4')};
	}
`;

export const ProductDiscountPrice = styled(Typography)`
	text-decoration: line-through;
	margin-right: ${getThemeSpace('4')};
`;

export const ProductPrice = styled(Typography)`
	margin-right: ${getThemeSpace('4')};
`;

export const ContentBottom = styled(Flex)`
	flex: 1;
`;

export const PromotionProductNameWrapper = styled(Flex)`
	overflow: hidden;
`;

export const FlexButton = styled(Flex)`
	background-color: var(--semantic-promo-text) !important;

	&:disabled {
		background-color: var(--button-disabled-background) !important;
	}
`;

export const FlexHorizontal = styled(Flex) <{ limitReached: boolean }>`
	${({ limitReached }) =>
		limitReached &&
		css`
			> div:not(.right-section) {
				opacity: 0.5;
			}
		`}
`;

export const ButtonChangeDistributor = styled(Button)`
	text-decoration: underline;
	color: var(--text-link);

	p {
		color: var(--text-link);
		font-size: ${getThemeFontSize('14')};
	}
`;