import { BUDataParams } from "./interfaces";

export const BUDataHandler = {
  handleDistributorAdd(BUData: BUDataParams, data: Record<string, any>) {
    const { distributorId, positionIndex } = data;

    const distributorAdded = BUData.available.distributors.find(
      (distributor) => distributor.id === distributorId,
    );

    if (!distributorAdded) return { ...BUData };

    const clonedSelected = [...BUData.selected.distributors];

    clonedSelected.splice(positionIndex, 0, distributorAdded);

    return {
      ...BUData,
      selected: {
        ...BUData.selected,
        distributors: clonedSelected,
      },
    };
  },

  handleDistributorRemove(BUData: BUDataParams, data: Record<string, any>) {
    const { distributorId } = data;

    const distributorRemoved = BUData.selected.distributors.find(
      (distributor) => distributor.id === distributorId,
    );

    delete distributorRemoved?.order;

    if (!distributorRemoved) return { ...BUData };

    return {
      available: BUData.available,
      selected: {
        ...BUData.selected,
        distributors: BUData.selected.distributors.filter((distributor) => distributor.id !== distributorId),
      },
    };
  },

  handleDistributorReorder(BUData: BUDataParams, data: Record<string, any>) {
    const { from, to } = data;

    return {
      ...BUData,
      selected: {
        ...BUData.selected,
        distributors: BUData.selected.distributors.changePosition<BUDataParams['selected']['distributors'][number]>(from, to),
      },
    };
  }
} 