import { Flex, Skeleton } from 'front-commons/ds';
import DistributorsDragAndDropSkeleton from 'containers/DragAndDrop/DistributorsDragAndDrop/Skeleton';

export default function DrawerDistributorSkeleton() {
	return (
		<Flex direction="column" overflow="auto" width="100%" padding="0 24px" gap="16px">
			<Skeleton height="24px" width="100%" />

			<Skeleton height="97px" width="100%" />

			<Flex width="100%" gap="16px" margin="24px 0 0 0">
				<Flex flex={1} display={{ small: 'none', medium: 'flex' }} direction="column">
					<Skeleton height="24px" width="100%" />
					<DistributorsDragAndDropSkeleton />
				</Flex>
				<Flex flex={1} direction="column">
					<Skeleton height="24px" width="100%" />
					<DistributorsDragAndDropSkeleton />
				</Flex>
			</Flex>
		</Flex>
	);
}
