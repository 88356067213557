import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { notify } from 'front-commons/ds';
import { NavigateFunction } from 'react-router-dom';
import {
	getBasketData,
	getBasketCheckoutData,
	getProductsUnavailable,
	removeProduct,
	initializeBasket,
} from 'services/basket';
import { BusinessUnitProps } from 'services/basket/interfaces';
import { updateCartGTM, viewCartGTM } from 'shared/gtm';
import { setBasket, setBasketCheckedState, setBasketDeleting, setChangedUnavailable, setLoading, setUnavailable, setValidTill } from './actions';
import { BasketReducer, HandleGetBasketDataParams, HandleRemoveProductParams } from './interfaces';

export const handleSetChangedUnavailable = (changedUnavailable: BasketReducer['changedUnavailable']): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setChangedUnavailable(changedUnavailable));
	};
};

export const handleSetDeleting = (deleting: BasketReducer['deleting']): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setBasketDeleting(deleting));
	};
};

export const handleBasketLoading = (loading: BasketReducer['loading']): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setLoading(loading));
	};
};

export const handleBasketCheckedState = (checkedBaskets: BasketReducer['checkedBaskets']): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setBasketCheckedState(checkedBaskets));
	};
};

export const handleLoadBasket = (posId: string): any => {
	return async (dispatch: Dispatch) => {
		try {
			await initializeBasket(posId);
			dispatch(setValidTill(dayjs().add(5, 'hours').unix()));
		} catch {
			notify.negative({ description: 'Não foi inicializar o carrinho' });
		}
	};
};

export const handleGetBasketData = ({
	posId,
	callGTM,
	loading,
	isEndBasketPage,
	initializeBefore,
	unavailableHandler,
}: HandleGetBasketDataParams): any => {
	return async (dispatch: Dispatch) => {
		if (!posId) return;

		try {
			dispatch(handleBasketLoading(loading));

			if (initializeBefore) await dispatch(handleLoadBasket(posId));

			const getData = isEndBasketPage ? getBasketCheckoutData : getBasketData;

			const response = await getData({ posId });

			dispatch(setBasket(response));

			dispatch(handleBasketCheckedState(response.baskets?.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})))

			if (isEndBasketPage) unavailableHandler?.get(true);

			if (callGTM) {
				viewCartGTM(
					Number(response.finalTotalPrice),
					response.baskets.map((basket) =>
						basket.products.map((product) => ({
							...product,
							affiliation: basket.distributorName,
							businessUnitName: basket.businessUnitName,
						})),
					),
				);
			}
		} catch (error) {
			notify.negative({ description: 'Não foi possível buscar produtos do carrinho' });
		} finally {
			dispatch(handleBasketLoading(false));
		}
	};
};

export const handleRemoveProduct = ({
	posId,
	productId,
	basketStore,
	isEndBasketPage,
}: HandleRemoveProductParams): any => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(handleSetDeleting(productId));

			await removeProduct(posId, productId);
			notify.positive({ description: 'Produto removido do carrinho' });

			const removedProduct = basketStore.basket.baskets.flatMap((basket) => {
				const product = basket.products.find((p) => p.id === productId);

				return {
					...product,
					affiliation: basket.distributorName,
					businessUnitName: basket.businessUnitName,
				};
			});


			updateCartGTM('remove_from_cart', basketStore.basket.finalTotalPrice || 0, [removedProduct], true);

			dispatch(handleGetBasketData({ posId, loading: 'refetch', isEndBasketPage }))
		} catch {
			notify.negative({ description: 'Não foi possível remover o produto do carrinho' });
		} finally {
			dispatch(handleSetDeleting(undefined));
		}
	};
};

export const handleClearUnavailable = (handleClearChangedUnavailable: VoidFunction): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setUnavailable(undefined));
		handleClearChangedUnavailable();
	};
};

export const handleGetUnavailable = (
	posId: string,
	navigate: NavigateFunction,
	handleClearChangedUnavailable: VoidFunction,
	redirectToAdjust: boolean
): any => {
	return async (dispatch: Dispatch) => {
		try {
			const response = await getProductsUnavailable(posId);

			dispatch(setUnavailable(response));

			if (response.hasInvalidProducts && redirectToAdjust) {
				navigate('/revisar-pedido/produtos-indisponiveis', { replace: true });
			}
		} catch (error) {
			notify.negative({
				description: 'Ocorreu um erro ao verificar os produtos indisponíveis no carrinho. Tente novamente mais tarde!',
			});
			navigate('/');
			dispatch(handleClearUnavailable(handleClearChangedUnavailable));
		} finally {
			setTimeout(() => {
				dispatch(handleBasketLoading(false));
			}, 200);
		}
	};
};

export const handleUpdateUnavailable = (products: BusinessUnitProps[]): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setUnavailable({ hasInvalidProducts: true, businessUnitToReview: products }));
	};
};

export const handleRemoveUnavailable = (productId: string, prevState: BasketReducer['unavailable']): any => {
	return async (dispatch: Dispatch) => {
		if (!prevState) return dispatch(setUnavailable(undefined));

		return dispatch(
			setUnavailable({
				hasInvalidProducts: prevState.hasInvalidProducts,
				businessUnitToReview: prevState.businessUnitToReview.reduce((acc, bu) => {
					const remainingProducts = bu.products.filter((product) => product.productId !== productId);

					if (!remainingProducts.length) return acc;

					return [
						...acc,
						{
							...bu,
							products: remainingProducts,
						},
					];
				}, [] as BusinessUnitProps[]),
			}),
		);
	};
};
