import { Button, Flex, Icon, Input, Dropdown, Typography, Tooltip } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useDebounce, useMediaQuery } from 'front-commons/hooks';
import { useEffect, useMemo, useState } from 'react';
import AddToBasketButton from 'containers/AddToBasketButton';
import PromotionProductCard from 'containers/Cards/PromotionProductCard';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import ProgressiveDiscountCards from 'containers/ProgressiveDiscountCards';
import { DistributorsProps } from 'services/products/interfaces';
import {
	getCurrentComponentRange,
	handlerDistributorValidity,
	hasQuantityChanged as handleHasQuantityChanged,
	hasUnableToUpdate,
	sortOptions,
} from 'shared/promotionDrawer';
import useCustomer from 'stores/customer';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import { PromotionDetailsDrawerProps } from './interfaces';
import Skeletons from './Skeletons';
import { FooterPromotionDetails, Wrapper, ButtonSortOption, WrapperOrdering, FlexFilters, FlexTooltip } from './styles';
import TotalComboPrice from './TotalComboPrice';
import TagCampaignLimiter from 'containers/Tags/TagCampaignLimiter';
import PromotionDrawerHeader from '../PromotionDrawerHeader';
import UnavailableMessage from 'containers/Cards/ProductCard/variants/PromotionProduct/components/UnavailableMessage';
import { useNavigate } from 'react-router-dom';

export default function PromotionDetails({
	data,
	basketData,
	promotionId,
	recoveryLastDistributor,
	orderDistributorByPrice,
	distributorWaiting,
	summary,
	loadingPromotion,
	loadingBasket,
	productsInComboPrices,
	brute,
	tax,
	discount,
	liquid,
	selectedDistributor,
	updatedProductsInCombo,
	flexAddLoading,
	notFoundProducts,
	sourceProductId,
	showReturnButton,
	CTAComponent,
	posId,
	disableDistributorSelector,
	shouldShowChangeDistributorsButton,
	dispatchGTMBasketEvent,
	getProductValidity,
	getFlexQuantityInBasket,
	updateSummary,
	handleChangeDistributor,
	handleRemovePromotion,
	handleAddToBasketClick,
	getSearchedProducts,
	handleSortItems,
	getProductsInBasketByType,
}: PromotionDetailsDrawerProps) {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState('');
	const [expanded, setExpanded] = useState(false);
	const [sortSelected, setSortSelected] = useState<{ name?: string; identifier?: number }>({});
	const [, setClosing] = useState(false);

	const { handleHasPermission } = useCustomer();
	const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer();

	const { posStore } = usePos();
	const pos = posId ? { id: posId } : posStore.selectedPos;

	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.small})`);
	const hasPermission = handleHasPermission('BASKET_EDIT');

	const greaterProductInComboAmount = data.productsInCombo?.map((child) => child.quantity).sort((a, b) => b - a)?.[0];

	const maxFixedQuantity =
		data.fixedAmount && greaterProductInComboAmount ? Math.floor(99999 / greaterProductInComboAmount) : undefined;

	const isOpen = isDrawerOpen('promotion-details-drawer');

	const hasValidDistributors = handlerDistributorValidity(data);
	const hasQuantityChanged = handleHasQuantityChanged(summary, promotionId, basketData) && !data?.fixedAmount;
	const debouncedValue = useDebounce(searchValue, 400);

	const productsInCombo = useMemo(() => {
		const foundedProduct = data.productsInCombo?.find(({ productId }) => productId === sourceProductId);

		if (sortSelected.identifier || searchValue || !sourceProductId || !foundedProduct) return data.productsInCombo;

		return [foundedProduct, ...(data.productsInCombo?.filter(({ productId }) => productId !== sourceProductId) || [])];
	}, [sourceProductId, searchValue, sortSelected, data.productsInCombo]);

	const addedProductsInCombo = useMemo(
		() => updatedProductsInCombo.filter((item) => item.quantity > 0),
		[updatedProductsInCombo],
	);

	const allProductsQuantity = useMemo(() => {
		const foundedProductsQuantity = summary.reduce((acc, curr) => {
			if (curr.quantity) {
				return acc + curr.quantity;
			}

			return acc;
		}, 0);

		return foundedProductsQuantity;
	}, [summary]);

	const currentQuantityInBasket = useMemo(
		() => basketData?.baskets?.flatMap(({ products }) => products).find(({ id }) => id === promotionId)?.quantity,
		[basketData, data, promotionId],
	);

	const currentBasketDistributor = data?.distributors?.find(({ distributorId }) => {
		return (
			distributorId ===
			basketData?.baskets?.find(({ products }) => products.find(({ id }) => id === promotionId))?.distributorId
		);
	});

	const addToCartLabel = useMemo(() => {
		if (data.minQuantityDistinctProducts) {
			if (addedProductsInCombo.length >= data.minQuantityDistinctProducts) return 'Adicionar ao carrinho';

			return 'Promoção bloqueada';
		}

		if (currentQuantityInBasket) return 'Atualizar';

		return 'Adicionar';
	}, [data.minQuantityDistinctProducts, currentQuantityInBasket, addedProductsInCombo.length]);

	const handleTransitionCloseDrawer = async () => {
		setClosing(true);

		await Promise.fake({ delay: 250 });

		handleCloseDrawer(CTAComponent ? 'import-promotion-details-drawer' : 'promotion-details-drawer');
	};

	const handleRedirectToDistributorsPage = () => {
		navigate(`/minhas-farmacias/distribuidores?f=${posId}`);
	};

	useEffect(() => {
		getSearchedProducts(promotionId, pos.id, searchValue);
	}, [debouncedValue]);

	useEffect(() => {
		if (promotionId && isOpen) {
			setSearchValue('');
			setSortSelected({});
		}
	}, [promotionId, isOpen]);

	return (
		<Wrapper className="wrapper-drawer" direction="column" gap="12px">
			{['full', 'opening'].includes(loadingPromotion) ? (
				<Skeletons />
			) : (
				<>
					<PromotionDrawerHeader
						endDate={data.endDate}
						description={data.description}
						showReturnButton={showReturnButton}
						onTransitionCloseDrawer={handleTransitionCloseDrawer}
					/>

					<Flex padding={{ small: '0px 16px', medium: '0px 24px' }} direction="column" gap="12px">
						<Flex direction="column" gap="8px">
							{data.limitedByQuantity && (
								<TagCampaignLimiter availableLimit={data.availableLimit} alignment="flex-start" />
							)}
							{data?.moreAbout && <Typography variant="ParagraphSmall/Regular">{data.moreAbout}</Typography>}
							{data?.hyperaCode && (
								<Typography variant="ParagraphSmall/Regular" color="--text-disable">
									ID: {data.hyperaCode?.toUpperCase()}
								</Typography>
							)}
						</Flex>

						{!!data?.distributors?.length && hasValidDistributors && (
							<Flex gap="8px" direction="column">
								<Typography>Distribuidores disponíveis</Typography>

								<DistributorsSelector
									initialValue={currentBasketDistributor as DistributorsProps}
									recoveryLastDistributor={recoveryLastDistributor}
									data={data.distributors as DistributorsProps[]}
									onChange={handleChangeDistributor}
									orderByLowerPrice={orderDistributorByPrice}
									isPromotionDrawer
									waiting={distributorWaiting}
									productId={data.hyperaCode}
									showFooter={false}
									disabled={disableDistributorSelector}
								/>
							</Flex>
						)}

						{(!data?.distributors?.length || !hasValidDistributors) && (
							<UnavailableMessage
								id={promotionId}
								productType="Promoção"
								alignItems="center"
								justifyContent="center"
								shouldShowReturnButton={true}
								shouldShowUpdateButton={!!shouldShowChangeDistributorsButton && !!data.hasOtherAvailableDistributor}
								handleRedirectToDistributorsPage={!!CTAComponent ? handleRedirectToDistributorsPage : undefined}
							/>
						)}
					</Flex>

					<Flex
						padding={{ small: '12px 16px', medium: '12px 24px' }}
						direction={{ small: 'column', medium: 'row' }}
						position="sticky"
						alignItems={{ small: 'flex-start', medium: 'center' }}
						top={{ small: '80px', medium: '54px' }}
						zIndex={999}
						gap="16px"
						backgroundColor="--surface-white"
					>
						<Typography variant="Paragraph/Semibold" whiteSpace="nowrap">
							Itens do combo
						</Typography>

						<FlexFilters width="100%">
							<Input
								name="filter_product"
								onChange={(el) => {
									el.stopPropagation();
									setSearchValue(el.target.value);
								}}
								value={searchValue}
								placeholder="Busque pelo nome ou EAN"
								width="100%"
								size="small"
								leftIcon={{ name: 'search' }}
							/>

							<Dropdown
								triggerContent={
									<Flex style={{ cursor: 'pointer' }} alignItems="center" justifyContent="center">
										<Icon name="swap_vert" size="24px" />
									</Flex>
								}
								side="bottom"
								offset={4}
								useExpanded
								expanded={expanded}
								onOpenChange={() => setExpanded((prevState) => !prevState)}
								radiusFullDesktop
							>
								<WrapperOrdering direction="column" gap="0px" padding="4px 0px">
									{sortOptions.map((item) => (
										<ButtonSortOption
											key={item.identifier}
											width="100%"
											variant="text"
											onClick={() => {
												setExpanded(false);
												setSortSelected(item);
												handleSortItems(item.identifier);
											}}
											selected={item.identifier === sortSelected.identifier}
										>
											{item.name}
										</ButtonSortOption>
									))}
								</WrapperOrdering>
							</Dropdown>
						</FlexFilters>
					</Flex>

					{(loadingPromotion === 'refetch' || loadingBasket) && <Skeletons.Products isTablet={isTablet} />}

					{notFoundProducts && (
						<Flex direction="column" gap="0px" padding={{ small: '12px 16px', medium: '12px 24px' }} flex="1">
							<Flex alignItems="center" justifyContent="center" direction="column" gap="8px" flex="1">
								<Icon name="deployed_code_alert" size="48px" />
								<Typography>Nenhum produto encontrado</Typography>
							</Flex>
						</Flex>
					)}

					<Flex
						direction="column"
						gap="0px"
						padding={{ small: '12px 16px', medium: '12px 24px' }}
						flex="1"
						display={loadingPromotion === 'refetch' || loadingBasket || notFoundProducts ? 'none' : 'flex'}
					>
						{productsInCombo?.map((item) => (
							<PromotionProductCard
								hasPermission={hasPermission}
								key={item.productId}
								product={item}
								basketData={basketData}
								promotionId={promotionId}
								hasRangeProduct={data.hasRangeProduct}
								mixedRanges={data?.ranges}
								fixedAmount={data.fixedAmount}
								rangeByQuantity={data.rangeByQuantity}
								quantityInBasket={currentQuantityInBasket}
								progressiveDiscount={data.progressiveDiscount}
								progressiveDiscountProductMix={data.progressiveDiscountProductMix}
								hasValidDistributors={hasValidDistributors}
								productsInPromotionPrices={productsInComboPrices}
								summary={summary}
								currentSubtotal={brute}
								updateSummary={updateSummary}
								getFlexQuantityInBasket={getFlexQuantityInBasket}
								getProductValidity={getProductValidity}
								allProductsQuantity={allProductsQuantity}
								posId={posId}
								onOpenDrawer={handleOpenDrawer}
								getProductsInBasketByType={getProductsInBasketByType}
							/>
						))}
					</Flex>

					<FooterPromotionDetails
						padding={{ small: '16px 8px', medium: '16px 24px' }}
						direction="column"
						gap="16px"
						position="sticky"
						bottom="0"
						backgroundColor="--surface-white"
					>
						{!!data?.ranges?.length && !data?.progressiveDiscount && (
							<Flex margin={{ small: '0 -2px', medium: '0' }}>
								<ProgressiveDiscountCards
									data={{ type: data?.rangeByQuantity ? 'quantity' : 'currency', values: data?.ranges }}
									atualValue={getCurrentComponentRange(
										data?.rangeByQuantity,
										data?.progressiveDiscount,
										summary,
										currentQuantityInBasket,
									)}
								/>
							</Flex>
						)}

						<TotalComboPrice discount={discount} tax={tax} liquid={liquid} brute={brute} />

						<Flex
							gap="8px"
							width="100%"
							justifyContent="space-between"
							alignItems="center"
							direction={{ small: 'column', medium: 'row' }}
						>
							{CTAComponent && CTAComponent}
							{!CTAComponent && (
								<>
									<Flex alignItems="center" gap="8px">
										{!!data.minQuantityDistinctProducts && (
											<>
												<Typography variant="ParagraphSmall/Regular" color="--text-secondary">
													{addedProductsInCombo.length} /{' '}
													<Typography variant="ParagraphSmall/Semibold" color="--text-primary">
														{data.minQuantityDistinctProducts}
													</Typography>{' '}
													Produtos para desbloquear
												</Typography>

												<FlexTooltip>
													<Tooltip
														content="É necessário a escolha de produtos distintos para desbloquear a promoção."
														maxWidth="174px"
													>
														<Icon name="info" color="--text-primary" size="16px" fill />
													</Tooltip>
												</FlexTooltip>
											</>
										)}
									</Flex>

									<Flex
										alignItems="center"
										gap="8px"
										direction={{ small: 'column', medium: 'row' }}
										width={{ small: '100%', medium: 'auto' }}
									>
										{!!currentQuantityInBasket && (
											<Button
												width={{ small: '100%', medium: '160px' }}
												variant="secondary"
												onClick={handleRemovePromotion}
												hasPermission={hasPermission}
											>
												Remover
											</Button>
										)}
										{data?.fixedAmount && (
											<AddToBasketButton
												posId={pos.id}
												hasPermission={hasPermission}
												buId={data?.businessUnitId}
												disabled={!hasValidDistributors}
												productId={promotionId}
												distributorId={selectedDistributor?.distributorId}
												counterOptions={{ width: '100%', maxWidth: '160px', hasPermission }}
												productsInCombo={updatedProductsInCombo}
												initialQuantity={currentQuantityInBasket}
												onUpdateBasketType={dispatchGTMBasketEvent}
												maxQuantity={Math.min(data.availableLimit ?? 99999, maxFixedQuantity ?? 99999)}
												disableAfterAdd
												suggestionGroupId={data?.suggestionGroupId}
												suggestionGroupName={data?.suggestionGroupName}
											/>
										)}

										{!data?.fixedAmount && (
											<Button
												width={{
													small: '100%',
													medium: ['Adicionar ao carrinho', 'Promoção bloqueada'].includes(addToCartLabel)
														? '236px'
														: '160px',
												}}
												onClick={handleAddToBasketClick}
												loading={flexAddLoading}
												disabled={hasUnableToUpdate({
													hasQuantityChanged,
													currentQuantityInBasket,
													hasValidDistributors,
													minQuantityDistinctProducts: data.minQuantityDistinctProducts,
													addedProductsInComboUnits: addedProductsInCombo.length,
												})}
												leftIcon={{
													name: addToCartLabel === 'Promoção bloqueada' ? 'lock' : 'shopping_cart',
													color: '--text-invert',
													detached:
														addToCartLabel === 'Promoção bloqueada' ? !!data.minQuantityDistinctProducts : false,
												}}
												hasPermission={hasPermission}
											>
												{addToCartLabel}
											</Button>
										)}
									</Flex>
								</>
							)}
						</Flex>
					</FooterPromotionDetails>
				</>
			)}
		</Wrapper>
	);
}
