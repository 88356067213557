import { BasketReducer } from './interfaces';

const initialState: BasketReducer = {
	basket: {} as BasketReducer['basket'],
	loading: false,
	unavailable: undefined,
	validTill: undefined,
	deleting: undefined,
	changedUnavailable: [],
	checkedBaskets: {}
};

export default function basketReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_CLEAN_BASKET': {
			return initialState;
		}

		case 'SET_BASKET': {
			return { ...state, basket: payload.basket };
		}

		case 'SET_BASKET_DELETING': {
			return { ...state, deleting: payload.deleting };
		}

		case 'SET_BASKET_LOADING': {
			return { ...state, loading: payload.loading };
		}

		case 'SET_BASKET_UNAVAILABLE': {
			return { ...state, unavailable: payload.unavailable };
		}

		case 'SET_BASKET_CHANGED_UNAVAILABLE': {
			return { ...state, changedUnavailable: payload.changedUnavailable };
		}

		case 'SET_BASKET_VALID_TILL': {
			return { ...state, validTill: payload.validTill };
		}

		case 'SET_BASKET_CHECKED_STATE': {
			return { ...state, checkedBaskets: payload.checkedBaskets };
		}

		default:
			return state;
	}
}
