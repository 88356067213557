import { Flex, GlassButtonContainer, notify } from 'front-commons/ds';
import { useFetch } from 'front-commons/hooks';
import { useEffect, useState } from 'react';
import DistributorsTabs from 'containers/DistributorsTabs';
import { findSelectedDistributors, orderOrAddDistributors } from 'services/pos';
import { BUDataParams, CatalogDistributorChangeDrawerProps } from './interfaces';
import DrawerDistributorSkeleton from './components/Skeleton';
import { getDistributorsPromotion } from 'services/products';
import { BUDataHandler } from './helpers';
import usePos from 'stores/pos';
import useDrawer from 'stores/drawer';
import PromotionDrawerHeader from 'containers/Drawers/components/PromotionDrawerHeader';
import { ProductParams } from 'services/products/interfaces';
import { CustomError } from 'shared/errors';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import { CustomerAccess } from 'stores/customer/interfaces';

const initialBUData = {
	businessUnitId: '',
	businessUnitName: '',
	distributors: [],
	pedEnabled: false,
};

export default function DistributorChangeCatalog({
	promotionId,
	showReturnButton,
	onAfterChangeDistributors,
}: CatalogDistributorChangeDrawerProps) {
	const [BUData, setBUData] = useState<BUDataParams>({
		selected: initialBUData,
		available: initialBUData,
	});
	const [promotionDetails, setPromotionDetails] = useState({} as Pick<ProductParams, 'description' | 'endDate'>);
	const [shouldShowEmptyColumns, setSHouldShowEmptyColumns] = useState(false);
	const [updatingDistributos, setUpdatingDistributors] = useState(false);

	const {
		posStore: { selectedPos },
		interceptDistributorRemoval,
		handleUpdateDistributor,
	} = usePos();
	const { simulatedViewType } = useCustomer();

	const { handleOpenDrawer, handleCloseDrawer, handleCloseAllDrawers } = useDrawer();

	const { handleGetBasketData } = useBasket();

	const { fetch: handleGetSelectedDistributors, loading: loadingSelected } = useFetch({
		fetchFunction: findSelectedDistributors, // * POS Distributors
		fetchParams: { posId: selectedPos.id },
	});

	const { fetch: handleGetDistributorByPromotion, loading: loadingAll } = useFetch({
		fetchFunction: getDistributorsPromotion, // * all distributors available for product
		fetchParams: { posId: selectedPos.id, promotionId },
	});

	const handleGetDistributorsList = async () => {
		const [selectedDistributorsForPOS, BUAndDistributorsAvailable] = await Promise.all([
			handleGetSelectedDistributors(),
			handleGetDistributorByPromotion(),
		]);

		if (BUAndDistributorsAvailable?.distributors.length) setSHouldShowEmptyColumns(true);

		setPromotionDetails({
			description: BUAndDistributorsAvailable?.description || '',
			endDate: BUAndDistributorsAvailable?.endDate,
		});

		setBUData({
			selected:
				selectedDistributorsForPOS?.find((BU) => BU.businessUnitId === BUAndDistributorsAvailable?.businessUnitId) ||
				initialBUData,
			available: {
				businessUnitId: BUAndDistributorsAvailable?.businessUnitId || '',
				businessUnitName: BUAndDistributorsAvailable?.businessUnitName || '',
				distributors: BUAndDistributorsAvailable?.distributors || [],
				pedEnabled: false,
			},
		});
	};

	const handleChangeDistributor = async (type: 'add' | 'reorder' | 'remove', data: Record<string, any>) => {
		try {
			if (type === 'remove') {
				await interceptDistributorRemoval({
					businessUnitId: BUData.selected.businessUnitId,
					distributorId: data.distributorId,
				});
			}

			setBUData((prevState) => {
				switch (type) {
					case 'add': {
						return BUDataHandler.handleDistributorAdd(prevState, data);
					}

					case 'reorder': {
						return BUDataHandler.handleDistributorReorder(prevState, data);
					}

					case 'remove': {
						return BUDataHandler.handleDistributorRemove(prevState, data);
					}

					default:
						return { ...prevState };
				}
			});
		} catch (err) {
			if (err instanceof CustomError && err.code === 'open-cart') {
				handleCloseAllDrawers();
				await Promise.fake({ delay: 50 });
				handleOpenDrawer('basket-drawer');
			}

			console.log('cancel');
		}
	};

	const handleUpdatePOSDistributors = async () => {
		try {
			setUpdatingDistributors(true);

			await orderOrAddDistributors({
				id: selectedPos.id,
				data: {
					businessUnitId: BUData.selected.businessUnitId,
					distributors: BUData.selected.distributors.map(({ id }, index) => ({
						distributorId: id,
						order: index,
					})),
				},
			});

			notify.positive({
				description: 'Seleção de distribuidores atualizados com sucesso.',
			});

			await handleGetBasketData({ loading: 'refetch', initializeBefore: true });

			await handleUpdateDistributor(BUData.selected as any);

			handleCloseDrawer('catalog-distributor-change');

			onAfterChangeDistributors?.();
		} catch (err) {
			console.log(err);
			notify.negative({
				description: 'Não foi possível atualizar a lista de distribuidores! Por favor, tente novamente.',
			});
			setUpdatingDistributors(false);
		}
	};

	useEffect(() => {
		handleGetDistributorsList();
	}, []);

	return (
		<Flex direction="column" overflow="auto" height="100%" gap="16px">
			<PromotionDrawerHeader
				loading={loadingSelected || loadingAll}
				description={promotionDetails.description}
				endDate={promotionDetails.endDate}
				showReturnButton={showReturnButton}
				onTransitionCloseDrawer={() => handleCloseDrawer('catalog-distributor-change')}
			/>

			{(loadingSelected || loadingAll) && <DrawerDistributorSkeleton />}

			{!(loadingSelected || loadingAll) && (
				<Flex overflow="auto" width="100%" padding="0 24px" gap="16px">
					<DistributorsTabs
						hideSearch
						isDrawer
						type="MASSIVE"
						BUId={BUData.selected?.businessUnitId}
						available={BUData.available as any}
						selected={BUData.selected as any}
						onAdd={(distributorId, positionIndex) => handleChangeDistributor('add', { distributorId, positionIndex })}
						onReorder={(from, to) => handleChangeDistributor('reorder', { from, to })}
						onRemove={(distributorId) => handleChangeDistributor('remove', { distributorId })}
						forceShowColumns={shouldShowEmptyColumns}
						emptyAvailableDistributorsMessage={
							<strong>Não foram encontrados distribuidores que atendam à promoção selecionada.</strong>
						}
						alertMessage={
							BUData.available.distributors.length
								? 'A alteração dos distribuidores pode afetar o seu carrinho e suas próximas compras.'
								: undefined
						}
						customDescription="Sua farmácia precisa ter um pré-cadastro no distribuidor para realizar seu pedido."
						CustomCTA={
							<GlassButtonContainer
								textButton={{ children: 'Cancelar', onClick: () => handleCloseDrawer('catalog-distributor-change') }}
								rightButton={{
									children: 'Salvar alterações',
									onClick: handleUpdatePOSDistributors,
									loading: updatingDistributos,
									disabled: simulatedViewType() === CustomerAccess.BASIC || !BUData.selected.distributors.length,
								}}
								width="100%"
								justifyContent="flex-end"
								bottom="0"
							/>
						}
					/>
				</Flex>
			)}
		</Flex>
	);
}
