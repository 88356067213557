import { SideDrawer, Toaster } from 'front-commons/ds';
import { SideDrawerProps } from 'front-commons/ds/components/SideDrawer/interfaces';
import { useEffect, useMemo } from 'react';
import useDrawer from 'stores/drawer';

export default function RegisteredDrawers() {
	const { handleCloseAllDrawers, drawerStore } = useDrawer();

	const drawersOpenedMemo = useMemo(() => {
		return drawerStore.drawers;
	}, [drawerStore.drawers]);

	useEffect(() => {
		document.body.style.overflow = drawersOpenedMemo.length > 0 ? 'hidden' : 'initial';
	}, [drawersOpenedMemo]);

	return (
		<>
			<Toaster target="drawer" openedDrawerProps={drawersOpenedMemo?.[0] as SideDrawerProps} />
			{drawerStore.drawers.map(({ children, ...rest }, index) => (
				<SideDrawer
					key={index}
					position={index + 1}
					onClose={() => {
						handleCloseAllDrawers();
						rest.data?.onClose?.();
					}}
					{...rest}
					subtitle={drawerStore.dynamicSubtitle.find(({ drawerId }) => drawerId === rest.id)?.text}
				>
					{children}
				</SideDrawer>
			))}
		</>
	);
}
