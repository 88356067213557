import { Br, Flex, Icon } from 'front-commons/ds';
import { FlexProps } from 'front-commons/ds/components/Flex/interfaces';
import useDrawer from 'stores/drawer';
import { ButtonChangeDistributor } from '../styles';

export default function UnavailableMessage({
	productType,
	id,
	shouldShowUpdateButton,
	shouldShowReturnButton,
	handleRedirectToDistributorsPage,
	...props
}: Partial<FlexProps> & {
	productType: 'Promoção' | 'Produto';
	id?: string;
	shouldShowUpdateButton: boolean;
	shouldShowReturnButton?: boolean;
	handleRedirectToDistributorsPage?(): void;
}) {
	const { handleOpenDrawer } = useDrawer();

	return (
		<Flex
			{...props}
			justifyContent="center"
			flex="1"
			border="1px"
			borderStyle="solid"
			borderColor="--semantic-warning-base"
			backgroundColor="--semantic-warning-weak"
			padding="8px 16px"
			borderRadius="8px"
			style={{ backgroundColor: '#FFF6ED' }}
		>
			<Flex gap="10px" alignItems="center" width="100%">
				<Icon name="warning" color="--semantic-warning-base" size="24px" fill />

				<Flex
					as="p"
					wrap="wrap"
					gap="2px"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					style={{ fontSize: '14px' }}
				>
					{productType} indisponível na sua
					<Br display={{ small: 'block', medium: 'none' }} /> seleção de distribuidores.
					{shouldShowUpdateButton && (
						<ButtonChangeDistributor
							variant="none"
							onClick={() => {
								if (handleRedirectToDistributorsPage) return handleRedirectToDistributorsPage();

								handleOpenDrawer('catalog-distributor-change', {
									promotionId: id,
									showReturnButton: shouldShowReturnButton,
								});
							}}
						>
							Alterar distribuidores
						</ButtonChangeDistributor>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
}
