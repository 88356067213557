import { Flex, Button, Typography, notify } from 'front-commons/ds';
import { useFetch } from 'front-commons/hooks';
import { useState } from 'react';
import { BusinessUnitResponseEnum } from 'pages/pos/Distributors/interfaces';
import { getMassiveProcessingStatus, saveMassiveDistributors } from 'services/pos';
import { updateLoopingProviderGTM } from 'shared/gtm';
import useDialog from 'stores/dialog';
import { DistributorsCallToActionProps } from './interfaces';

export default function ColumnsCTA({
	buId,
	type,
	canBuy,
	posList,
	currentBUName,
	anotherBUInfos,
	loadingPharmacy,
	selectedDistributors,
	hasSelectedDistributorsChanged,
	setActiveTab,
	handleClickMakeOrder,
	setLastDistributorConfigSaved,
}: DistributorsCallToActionProps) {
	const [processing, setProcessing] = useState(false);
	const { handlePreventDataLoss } = useDialog();

	const { fetch: getProcessingStatus } = useFetch({
		fetchFunction: getMassiveProcessingStatus,
		recursive: true,
		recursiveTime: 1000,

		recursiveTill(response) {
			return response?.status === 'PROCESSED';
		},

		onRecursiveStops(response) {
			setProcessing(false);

			if (response?.status === 'PROCESSED') {
				handlePreventDataLoss.disable();
				notify.positive({
					description: (
						<Typography>
							Suas alterações de <strong>{currentBUName}</strong> foram salvas.
						</Typography>
					),
				});
			}
		},

		onBeforeFetch() {
			setProcessing(true);
			handlePreventDataLoss.enable();
		},

		onError() {
			notify.negative({ description: 'Erro ao buscar o status do processamento.' });
			setProcessing(false);
		},
	});

	const { fetch: fetchSaveDistributors, loading } = useFetch({
		fetchFunction: saveMassiveDistributors,
		onSuccess(response) {
			if (!response) return;

			getProcessingStatus({ loopingProcessingId: response.loopingProcessingId });
		},

		onError() {
			notify.negative({ description: 'Erro ao salvar os distribuidores.' });
		},
	});

	const handleSaveMassiveDistributors = () => {
		selectedDistributors?.[BusinessUnitResponseEnum.Distributors].forEach((distributor, index) => {
			updateLoopingProviderGTM('massive_looping_provider', {
				distributorName: distributor.summarizedCorporateName,
				businessUnitName: selectedDistributors[BusinessUnitResponseEnum.BusinessUnitName],
				index,
				minimumValue: 0,
			});
		});

		setLastDistributorConfigSaved?.((prevState) => ({
			...prevState,
			[buId!]: selectedDistributors?.[BusinessUnitResponseEnum.Distributors]!,
		}));

		fetchSaveDistributors({
			businessUnitId: buId,
			pointOfSaleIdList: posList,
			distributorList: selectedDistributors?.[BusinessUnitResponseEnum.Distributors].map((distributor, index) => ({
				distributorId: distributor.id,
				order: index + 1,
			})),
		});
	};

	if (type === 'MASSIVE')
		return (
			<Flex margin="0 0 0 auto">
				<Button
					size="large"
					onClick={handleSaveMassiveDistributors}
					disabled={
						!selectedDistributors?.[BusinessUnitResponseEnum.Distributors].length || !hasSelectedDistributorsChanged
					}
					loading={loading || processing}
				>
					<Typography>Salvar looping de {currentBUName}</Typography>
				</Button>
			</Flex>
		);

	const buName = `Ir para ${anotherBUInfos?.name}`;

	return (
		<Flex direction="column" width="100%" gap={{ small: '32px', medium: '24px' }} margin={{ medium: '16px 0 0 0' }}>
			{anotherBUInfos?.name && (
				<Button
					isFullWidth
					onClick={() => setActiveTab?.(anotherBUInfos.id)}
					loading={!!loadingPharmacy}
					size="large"
					disabled={!anotherBUInfos.enabled}
				>
					{buName}
				</Button>
			)}

			<Button
				onClick={handleClickMakeOrder}
				loading={!!loadingPharmacy}
				variant="secondary"
				size="large"
				disabled={!canBuy}
				isFullWidth
			>
				Ir para loja
			</Button>
		</Flex>
	);
}
