import { AxiosError } from 'axios';
import { BusinessUnitResponse } from 'pages/pos/Distributors/interfaces';
import { createAPIConnection } from 'services';
import { ResponseParams } from 'services/interfaces';
import { simulatedViewType } from 'stores/customer/helpers';
import { getStore } from 'stores/helpers';
import { getSimulatedPosListManage } from './helpers';
import {
	DataValidationResponse,
	FindPharmacyRequest,
	FindPharmacyResponse,
	QueryParams,
	FindMyPharmaciesResponse,
	OrderOrAddDistributorsRequest,
	DeleteDistributorsRequest,
	PharmacyDistributorsCheckRequest,
	DistributorSelectOrderRequest,
	FindPharmaciesResponse,
	SolicitationListQueryParams,
	PharmaciesListAccessResponse,
	SolicitationListResponse,
	BannersResponse,
	DistributorsOrderResponse,
	SimulatedPosSuggestionsResponse,
	PreValidationComponentProps,
	PreValidationRequest,
	SupervisorChangeResponse,
	SelectedFlagResponse,
	GetDistributorsFiltersResponse,
	FindPharmaciesRequest,
	GetMassiveDistributorsListRequest,
	GetMassiveDistributorsListResponse,
	SaveMassiveDistributorsRequest,
	SaveMassiveDistributorsResponse,
	GetMassiveProcessingStatusResponse,
	GetMassiveProcessingStatusRequest,
	PosLinkValidateRequest,
	PosLinkValidateResponse,
	PosLinkGenerateRequest,
	PosLinkStatusResponse,
	PosLinkGenerateResponse,
} from './interfaces';
import { requestHandler } from '../helpers';

const api = createAPIConnection('', {
	onRequest(config) {
		const provider = getStore().customerReducer?.provider;

		if (provider === 'ZECA_COPILOTO') Object.assign(config?.headers || {}, { 'Partner-origin': 'zecacopiloto' });
	},

	onReject(error) {
		if (error.config.url === '/pos/pre-validation/customer' && error.response.status !== 401) throw error.response;
	},
});

export const findPharmacy = async (params: FindPharmacyRequest) => {
	return requestHandler<FindPharmacyResponse>(api.get('/pos/detail', { params }));
};

export const validatePharmacyCnpj = async (params: QueryParams) => {
	return requestHandler<DataValidationResponse>(api.get('/pos/validate', { params }));
};

export const findMyPharmaciesNoHandler = async (params?: GenericObject) => {
	const response = await api.get<FindMyPharmaciesResponse>('/pos/list/customer', { params });

	return response?.data;
};

export const searchPosData = async (params: FindPharmaciesRequest = { summarized: true }) => {
	if (simulatedViewType()) {
		const simulatedPos = await getSimulatedPosListManage();

		return simulatedPos;
	}

	try {
		const data = await requestHandler<FindPharmaciesResponse[]>(api.post('/pos/list', params), { throwData: true });
		return data;
	} catch (error) {
		return []
	}

};

export const findDistributors = async ({ posId }: { posId: string }) => {
	return requestHandler<BusinessUnitResponse[]>(api.get(`/distributors/pos/${posId}`));
};

export const findSelectedDistributors = async ({ posId }: { posId: string }) => {
	return requestHandler<BusinessUnitResponse[]>(api.get(`/pos/${posId}/distributors`));
};

export const orderOrAddDistributors = async ({ id, data }: OrderOrAddDistributorsRequest) => {
	return requestHandler(api.post(`/pos/${id}/distributors`, data));
};

export const deleteDistributors = async ({ id, distributors: data }: DeleteDistributorsRequest) => {
	return requestHandler(api.delete(`/pos/${id}/distributors`, { data }));
};

export const distributorSelectOrder = async ({ pharmacyId, data }: DistributorSelectOrderRequest) => {
	return requestHandler(api.post(`/pos/${pharmacyId}/distributors/exibition`, data));
};

export const pharmacyDistributorsCheck = async (pharmacyId: string) => {
	return requestHandler<PharmacyDistributorsCheckRequest>(api.get(`/pos/${pharmacyId}/distributors/check`));
};

export const pharmacyOwnerSolicitationListAccess = async (pharmacyId: string, params: SolicitationListQueryParams) => {
	const { status } = params;
	return requestHandler<SolicitationListResponse>(
		api.get(`/pos/management/access/${pharmacyId}`, { params: { ...params, status: status?.join(',') } }),
	);
};

export const pharmacyOwnerApproveAccess = async (buyerCustomerId: string) => {
	return requestHandler<ResponseParams>(
		api.post(`/pos/management/access/accept`, { pointOfSaleBuyerCustomerId: buyerCustomerId }),
	);
};

export const pharmacyOwnerDenyAccess = async (buyerCustomerId: string) => {
	return requestHandler<ResponseParams>(
		api.post(`/pos/management/access/reject`, { pointOfSaleBuyerCustomerId: buyerCustomerId }),
	);
};

export const pharmacyOwnerBlockAccess = async (buyerCustomerId: string) => {
	return requestHandler<ResponseParams>(
		api.post(`/pos/management/access/block`, { pointOfSaleBuyerCustomerId: buyerCustomerId }),
	);
};

export const pharmaciesListOwnerAccess = async () => {
	if (simulatedViewType()) {
		const simulatedPos = await getSimulatedPosListManage();
		return simulatedPos;
	}

	return requestHandler<PharmaciesListAccessResponse[]>(api.get('/pos/management/access'));
};

export const getDataBanners = async (posId: string, tag: string) => {
	return requestHandler<BannersResponse[]>(api.get('/pos/banners', { params: { posId, tag } }));
};

export const getDistributorsOrder = async (pharmacyId: string) => {
	return requestHandler<DistributorsOrderResponse>(api.get(`/pos/${pharmacyId}/distributors/order`));
};

export const getUserSimulatedPosSuggestions = async ({ cnpj }: { cnpj: string }) => {
	try {
		const response = await api.get(`/pos/list/${cnpj}`);

		return response.data.content as SimulatedPosSuggestionsResponse[];
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.status;
		}

		throw error;
	}
};

export const preValidationCustomer = async (posData: PreValidationRequest) => {
	return requestHandler<PreValidationComponentProps>(api.post('/pos/pre-validation/customer', posData), {
		throwData: true,
	});
};

export const posSupervisorChange = async () => {
	return requestHandler<SupervisorChangeResponse>(api.get('/pos/supervisor-change'));
};

export const getFlags = async (params?: GenericObject) => {
	return requestHandler<SelectedFlagResponse>(api.get('/pos/commercial-flag/list', { params }));
};

export const getDistributorsFilters = async () => requestHandler<GetDistributorsFiltersResponse>(api.get('/pos/distributors/filters'));

export const getMassiveDistributorsList = async (filter: GetMassiveDistributorsListRequest) => requestHandler<GetMassiveDistributorsListResponse>(api.post('/pos/distributors/search', { filter }))

export const saveMassiveDistributors = async (data: SaveMassiveDistributorsRequest) => requestHandler<SaveMassiveDistributorsResponse>(api.post('/pos/distributors/save-massive', data))

export const getMassiveProcessingStatus = async ({ loopingProcessingId }: GetMassiveProcessingStatusRequest) => requestHandler<GetMassiveProcessingStatusResponse>(api.get(`/pos/distributors/massive/${loopingProcessingId}`))

export const posLinkValidate = async ({ cnpjList }: PosLinkValidateRequest) =>
	requestHandler<PosLinkValidateResponse>(api.post(
		'/pos/management/access/request/validate',
		{
			role: "BUYER",
			cnpjList
		}),
		{ throwData: true }
	);

export const posLinkGenerate = async ({ cnpjList }: PosLinkGenerateRequest) =>
	requestHandler<PosLinkGenerateResponse>(api.post(
		'/pos/management/access/request/generate',
		{
			role: "BUYER",
			cnpjList
		}),
		{ throwData: true }
	);

export const posLinkStatus = async ({ accessRequestId }: { accessRequestId: ObjectID }) =>
	requestHandler<PosLinkStatusResponse>(api.get(
		`/pos/management/access/request/${accessRequestId}/status`),
		{ throwData: true }
	);